@import "@blueprintjs/core/lib/css/blueprint.css";



* {
    box-sizing: border-box;
}

// 1. Classes
// 2. Elements

// 1. Classes

.flex-center {
    display: flex;
     align-items: center;
     justify-content: center;
}

.not-al-box{
    opacity: .3;
    cursor: not-allowed !important;
    pointer-events: none;
}

.font-style-10{
    font-size: 14px;
    margin: 4px 0;
}

.m-l-10{
    margin-left: 40px;
}

.flex-justify{
    display: flex;
    justify-content: space-between;
}




.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab[aria-selected=true]{
    color:darkorange;
}

// 2. Elements

// Popups

.bp5-dialog-header{
    align-items: center;
    border-bottom: 1px solid rgba(17,20,24,.15);
    display: flex;
    gap: 20px;
    justify-content: space-between;
    min-height: 50px;
    position: relative;
    width: 100%;
    text-transform: capitalize;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 1px 0 rgba(17, 20, 24, 0.15);
    flex: 0 0 auto;
    padding: 20px 40px !important;
    z-index: 0;
    background: #008080 !important;

    h6{
        color: white;
    }
    .bp5-dialog-close-button{
        svg{
            fill: #fff !important;
            width: 24px;
            height: 24px !important;
        }
    }
}

// Add event popup

.soccer-game-dialog .bp3-dialog {
    
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.soccer-game-dialog .bp3-dialog-header {
    align-items: center;
    // background: #ffffff;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 1px 0 rgba(17, 20, 24, 0.15);
    display: flex;
    flex: 0 0 auto;
    min-height: 40px;
    padding: 20px 32px;
    
}

.soccer-game-dialog .bp3-dialog-body {
    // background-color: #D0EFFF;
    color: #4A5568; /* Dark Slate Gray for the text */
    padding: 40px;
    // background-image: url("../images/ball.jpg");
    .bp5-input-group .bp5-input{
        box-shadow: none !important;
    }
}

.sp-select-btn{
    background: #fff !important;
    box-shadow: none !important;
    border: none !important;
    width: 100%;
    align-items: center !important;
    justify-content: space-between !important;
    height: 34px;
}

.soccer-game-dialog .bp3-dialog-footer {
    // background-color: #fb6c3f; /* Coral for the footer */
    padding: 20px 40px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: flex-end;
    background-color: #D0EFFF;
    border-top: 1px solid lightgrey;
    


    .bp5-button {
        border-radius: 3px;
        padding: 8px 24px !important;
        margin-left: 6px;
        border: 1px solid #fb6c3f;
        box-shadow: none !important;
        outline: none !important;

        &.bp5-button.bp5-intent-primary{
            background-color: #fb6c3f;
            color: #fff;
        }
    }
}

.soccer-game-dialog .bp3-input {
    // background-color: transparent;
    border: none;
    border-bottom: 2px solid #4A5568;
    margin-bottom: 15px;
}

.soccer-game-dialog .bp3-dialog-close-button {
    color: #D0EFFF;
}

// Info popup

.info-dialog.bp5-dialog{
    width: 650px;
    position: relative;
    .bp5-dialog-header{
        background: #fff !important;
        height: 60px;

        h6 {
            color: #1c2127;
        }
        .bp5-dialog-close-button svg {
            fill: #1c2127 !important;
        }

    }

    .info-box-body{
        padding: 40px 40px 0 40px;
    }
    
    .info-box-no-info{
        display: flex;
        width: 100%;
        height: 100%;
        flex: 1 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: midnightblue;
        font-size: 13px;
        margin-bottom: 50px;
        font-weight: 500;
    }

    .event-header{
        font-weight: 600;
        font-size: 19px;
        display: flex;
        justify-content: space-between;
        .bp5-icon-trash{
            cursor: pointer;
        }
    }

    .game-teams-label{
        // display: flex;
        // margin: 8px 0 6px 0;
        // font-size: 16px;
        // color: cadetblue;
        // font-weight: 600;
        display: flex;
        margin: 8px 0 6px 0;
        font-size: 16px;
        color: cadetblue;
        font-weight: 600;
        width: 100%;
        justify-content: space-evenly;
    }

    .footer-dialog-popup{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 32px 40px;
        border-top: 1px solid lightgrey;
    }
}





// Dropdown icons
.add-event-row {
    position: relative;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    color: orangered;
    font-size: 14px;
    font-weight: 500;
    height: 70px;
    width: 200px;
    .icon {
        position: absolute;
        bottom: -20px;
        width: 40px;
        height: 40px;
        opacity: 0;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        > div{
            color: midnightblue;
            font-size: 12px;
        }

        svg{
            margin-top: 6px;
            width: 20px !important;
            height: 20px;
            fill: midnightblue !important;
        }
    }
    
    .left-icon {
        left: 10px;
        bottom: -20px;
        transform: translateX(70px) translateY(-14px);
        opacity: 0;
        transition: all 0.3s ease;
        &.show{
            transform:  translateX(104px) translateY(0px);
            opacity: 1;
        }
    }    
    .right-icon {
        left: 10px;
        bottom: -20px;
        transform: translateX(50px) translateY(-14px);
        opacity: 0;
        transition: all 0.3s ease;
        &.show{
            transform:  translateX(38px) translateY(0px);
            opacity: 1;
        }
    }
    
    // .show {
    //     opacity: 1;
    //     transform: translateY(0px);
    // }
}

// Fields

.field-1{
    width: 100%;
    border: 1px solid white;
    padding: 35px 20px;
    background: green;
    background-image: url("../images/fieldSvg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 77vh;
    margin: 50px 0 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: floralwhite;
    font-weight: 500;
    .player{
        white-space: nowrap;
    }
    > div{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-bottom: 34px;

        &.row{
            flex-direction: row;

            >div{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 25%;
                align-items: center;

            }
        }
    }
    .player{
        color: #ffffff;
        font-family: "Arial", sans-serif;
        font-size: 13px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
        background-color: rgba(0, 0, 0, 0.4);
        padding: 5px 10px;
        border-radius: 5px;
        margin: 5px;
        width: 194px;
        /* height: 112px; */
        min-height: 100px;
   }
    .gk{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .defense{
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        width: 100%;
        // margin-top: 38px;
    }
    .AT{
        justify-content: space-between;
    }
    .INTER{
        justify-content: space-evenly;
    }
}

.inter-right{
    margin-right: 32px;
}
.inter-left{
    margin-left: 32px;
}


// 3. Layouts

// 3.1 Overview

.overview-layout {
    // height: 100vh;
    // background-color: #00c0ae;
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
    min-height: 100vh;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.content{
    height: calc(100vh - 166px);
    // background-color: darkcyan;
    color: floralwhite;

    .no-data{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size:16px;

        .plus-icon{
            font-weight: 700;
            font-size: 58px;
            cursor:pointer;
        }
    }
}

.bp5-tabs.bp5-vertical > .bp5-tab-list .bp5-tab{
    color: floralwhite;
}

.bp5-tab-list > *:not(:last-child) {
    margin-right: 20px;
    height: 40px !important;
}

// 3.2 Details

// Menu

.menu-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ul{
        margin-left: -12px;
        list-style-type: none;
        display: flex;
        li {
            padding: 6px 12px;
            a {
                text-decoration: none;
                color: #fff;
            }
        }
        &:last-child{
            margin-right: 40px;
            button{
                color: #fff;
                background: #0c0e13 !important;
            }
        }
    }
   
}

.App-header {
    background-image: url("../images/stadium.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.header-row{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-box{
        display: flex;
        > *{
            margin-left: 12px;
        }
    }
    // .sp-btn{
    //     margin-left: 12px;
    //     background: #5D8AA8;
    //     color: white;
    //     border-radius: 5px;
    //     font-size: 14px;
    // }
}

.sp-btn-group{
    //     // border: 1px solid orangered;
    display: flex;
    align-items: center;
    background: #5D8AA8;
    color: white;
    border-radius: 5px;
    padding: 2px 17px;
    font-size: 14px;
    
    .sp-btn{
        color: white;
    padding: 10px 0 9px 10px;
    background: #5D8AA8;
    border: none;
    position: relative;

        // margin-left: 12px;
        &:hover{
            outline: none;
        }        
        &:focus{
            outline: none;
        }
    }

    input::-webkit-inner-spin-button,
    input::-webkit-calendar-picker-indicator {
        color: white;
        opacity: 0;
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
    }
}

.sp-btn{
    background: #5D8AA8 !important;
    color: white !important;
    border-radius: 5px !important;
    padding: 13px 24px !important;
    font-size: 14px !important;
}

// 4. Grid

.grid-height {
    height: calc(100vh - 181px);
}

// 5. Components

// Calendar

.calendar-container {
    max-width: 100%;
    margin: 20px auto;
    font-family: Helvetica, Arial, sans-serif;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .month-navigation {
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: flex-end;

        button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 20px;
            color: #fff;

            &:hover {
                color: #106ba3;
            }

            &:focus {
                outline: none;
            }
        }

        h2 {
            margin: 0;
            font-size: 24px;
        }
    }

    .calendar-grid {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;
        padding-top: 10px;
    }

    .calendar-day {
        background: #4A5568;
        border: 1px solid #d8e1e8;
        border-radius: 3px;
        padding: 6px;
        position: relative;
        min-height: 100px;
        max-width: calc((100vw - 40px) / 7);
        display: flex;
        flex-direction: column;
        transition: transform 0.15s ease-in-out;

        .card-header{
            font-size: 10px;
            font-weight: 600;
            margin-bottom: 4px;
            display: flex;
            justify-content: space-between;
            .date-top-right{
                font-weight: 500;
                // font-size: 13px;
            }
        }

        .bp3-heading {
            // display: none;
            margin-top: 0;
            margin-bottom: 0px;
            margin-right: 4px;
            color: #88C0D0;
            
        }
        .card-description {
            font-size: 10px;
            margin: 0;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &.highlight-w-day{
            background-color: #3B4252;
            .bp3-heading {
                color: #fb6c3f;
            }
            .card-description {
                color: #E5E9F0 ;
            }
            // .date-top-right{
            //     color: #fb6c3f; 
            // }
        }

        &.highlight-today{
            background-color: #D0EFFF !important;
            .bp3-heading{
                color: orangered !important;
            }
            .date-top-right{
                color: #003366 !important; 
            }
            .card-description {
                color: #003366 !important; 
            }
        }

        &.highlight-game{
            background-color: #008080; /* Teal Background */
            .bp3-heading {
                color: floralwhite /* Dark Gray for headings */
            }
            .card-description {
                color: #FFD700; /* Gold for descriptions */
            }
            .date-top-right{
                color: floralwhite; 
            }
        }

        &.highlight-training{
            background-color: #5D8AA8;
            .bp3-heading {
                color: #A5D6A7 /* Dark Gray for headings */
            }
            .card-description {
                color: #E0E0E0; /* Gold for descriptions */
            }
            .date-top-right{
                color: floralwhite; 
            }
        }
    }
    svg[data-icon]{
        fill: #fff;
        height: 20px;
        width: 20px;
        display: flex;
    }
}

.calendar-day:hover {
    transform: scale(1.05);
}

.cols-3{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom:120px;

}

.players-lists{
    width: 100%;
    display:flex;

}

.selected-players-list{
    width: 50%;
}
.all-players-list{
    width:50%;
}

.mono-font{
    color: floralwhite;
    font-weight: 500;
    font-family: monospace;
    margin-top: 4px;
}

// Games overview
.hide-checkbox{
    .bp5-control input:checked ~ .bp5-control-indicator {
        width: 0px;
        display: none;
    }

}
.game-card {
    &.highlight-card{
        background-color: #5D8AA8;
        h5{
            color: #A5D6A7;
        }
    }
    .bp5-icon{
        cursor: pointer;
        padding: 4px 4px;
    }

}

.edit-dialog{
    .bp5-button{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.sub-description{
    display: flex;
    justify-content: space-between;
    > div{
        margin-right:12px;
    }
}

.all-players-list{
    .bp5-control bp5-checkbox{
        padding-left: 10px;
    }
    .hide-checkbox {
        input[type="checkbox"]{
            display: none;
        }
        .bp5-control-indicator{
            display: none;
        }
    }
}

.label-player{
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
}

.status-popup{
    min-width: 300px !important;
    min-height: 150px;
    display: flex;
    align-items: normal;
    flex-direction: column;
    position: absolute;
    top: 181px;
    left: 46px;
    background-color: white;
    border: 1px solid rgb(139 125 120);
    border-radius: 5px;
    z-index: 1000;
    transform-origin: center bottom;
    h3{
        color: #88C0D0;
        font-size: 20px;
        padding: 10px 10px 16px 16px;
        margin-top: 14px;
        font-weight: 500;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid lightgrey;
        background: #4A5568;

    }   
    .bp5-icon-cross{
        color: white;
        position: absolute;
        right: 12px;
        top: 13px;
        cursor: pointer;
        svg{
            width: 22px;
            height: 22px;
        }
    }
    .icons-row{
        padding: 4px 12px 20px 12px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;;
        height: 105px;
        .bp5-icon{
            width: 25%;
            width: 25%;
            align-items: center;
            display: flex;
            justify-content: center;
        }
        .bp5-icon-plus{
            color: red;
        }
        .bp5-icon-tick{
            color: green;
        }
        .bp5-icon-issue{
            color: purple;
        }
        svg{
            width: 30px;
            height: 30px;
        }
        
    } 
}

.game-rez-block{
    width: 100%;
    margin: 0;
    padding-bottom: 15px;
    min-height: 167px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.game-score-block{
    display: flex;
    justify-content: space-around;
    > div{
        font-size: 16px;
        font-weight: 700;
        color: orangered;
    }
}

.edit-icon{
    cursor: pointer;
    margin-right: 8px;
}
.asdf{
    padding-left: 6px;
    display: flex;
    .bp5-control:not(.bp5-align-right) {
        padding-left: 7px;
    }
}

.formation-row {
    display: flex;
    align-items: baseline;

    .sp-select{

        .sp-select-btn{
            background-color: transparent !important;
            color: #fff;        }
    }
}

// .AC{
//     position: relative;
//     top: 10px;
// }

.players-2{
    justify-content: center !important;
    > div{
        width: 30%;
        }
}

.field-btn{
    background: transparent !important;
    color: #fff !important;
    .bp5-icon{
        display: none;
    }
}

.form-box{
    padding: 10px 0;
    > div{
        margin: 20px 0;
        display: flex;
        > label{
            width: 50px;
        }
    }
}


// .field-1[data="all-players-4-2-3-1"]{
//     > div.row > div{
//         width: 28%;
//     }
//     .GK{
//         order: 1;
//     }
//     .defense{
//         margin-top: 91px;
//         order: 2;
//     }
//     // .nr6{
//     //     order: 4;
//     // }
//     .INTER{
//         order: 3;
//         justify-content: center;
//     }
//     .AT{
//         order: 5;
//     }
//     .AR, .AL{
//         top: -100px;
//         position: relative;    }
// }

// 9. Media queries

@media only screen and (max-width: 768px) {
    .overview-layout {
        // background-image: url("../images/bg1.jpg");
        background: black;
    }

    .menu-sidebar {
        flex-wrap: wrap;
        ul {
            li {
                a {
                    color: #fff;
                }
            }
            &:last-child {
                width: 100%;
                justify-content: flex-end;
            }
        }
    }

    #fields{
        max-width: 100%;

        .field-btn{
            font-size: 9px;
        }
        .players-3{
            >div{
                width: 30% !important;
            }
        }
        .players-2{
            >div{
                width: 40% !important;
            }
        }
    }

    .players-lists{
        label{
            font-size: 12px;
        }
    }

    .calendar-container {
        margin-top: 80px;
        h1{
            margin-left: 32px;
        }
        .month-navigation{
            justify-content: center;
        }
        .calendar-grid{
            gap: 3px;
        }

        .calendar-day{
            padding: 6px 4px;
            .card-header{
                font-size: 9px;

                flex-direction: column;
                .bp3-heading{
                    order: 2;
                    margin-top: 4px;
                }
                .date-top-right{
                    order: 1;
                }
            }
            .card-description {
                font-size: 8px;
           }
        }
    } 
    .overview-layout.ss-full-width{
        padding: 4px;
    }
    .header-row{
        h1{
            font-size:20px;
        }
        .right-box{
            .sp-btn-group{
                padding: 2px 0px;
                .btn-text{
                    display: none;
                }
                .sp-btn{
                    padding: 4px 0px 4px 12px !important;
                    font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "blueprint-icons-16", sans-serif;
                }
            }
        }
    }
    .field-1 {
        margin-left: -30px !important;
        margin-right: -30px !important;
        padding: 0;
        width: calc(100% + 44px) !important;
        margin: 20px 0;
        .player{
            white-space: nowrap;
            font-size: 8px;
            font-weight: 500;
        }
    }

    .all-players-list{
        white-space: nowrap;
        ol{
            padding-left: 5px;
        }
    }
    .selected-players-list{
        
        white-space: nowrap;
    }
    .bp5-control:not(.bp5-align-right){
        padding-left: 6px !important;
    }

    .fjkhkhkg{
        margin-left: 0;
        margin-top: 22px;
        padding-left: 20px;
        overflow: hidden;
    }

    

}


@media only screen and (min-width: 768px) {
    .overview-layout {
        background: black;
        // background-image: url("../images/bg1.jpg");
    }

    .save-btn{
        padding: 6px 32px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){

    
}

@media only screen and (min-width: 1024px) {
    .cols-3{
        flex-wrap: nowrap;
    }
    #fields{
        width: 50% !important;

    }

    .players-lists{
        width: 50%;
    }

    // .selected-players-list{
    //     width: 25%;
    // }

    .calendar-container {
        max-width: 75%;
        margin: 20px auto;
    
        .month-navigation {
            display: flex;
            align-items: center;
            padding: 10px;
            justify-content: center;
        }
    
        .calendar-grid {
            grid-template-columns: repeat(7, 1fr);
            gap: 10px;
            padding-top: 10px;
            .calendar-grid-item{
                width: 100%;
            }
        }
    
        .calendar-day {
            padding: 20px;
            min-height: 100px;
            height:100%;
            max-width: calc((100vw - 40px) / 7);
    
            .card-header{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 8px;
                .date-top-right{
                    width: 40px;
                    font-size: 12px;
                    white-space: nowrap;
                    padding-top: 1px; 
                    font-weight: 500;       
                }
            }
    
            .bp3-heading {
                margin-top: 0;
                margin-bottom: 0px;
                margin-right: 4px;
                width:90%;
                
            }
            .card-description {
                font-size: 12px;
                margin: 0;
                flex: 1;
                justify-content: flex-start;
                align-items: center;
            }
            
        }
        svg[data-icon]{
            fill: #fff;
            height: 20px;
            width: 20px;
            display: flex;
        }
    }
    
    .calendar-day:hover {
        transform: scale(1.05);
    }

    .info-dialog.bp5-dialog{
        width: 650px;
        min-height: 400px;
    }

}

