.game-card {
    background-color: #008080; /* Very dark cyan background for contrast on black */
    color: white;
    margin: 20px 0;
    padding: 12px 20px;
    border-radius: 8px;
    border-left: 5px solid darkcyan;
    box-shadow: 0 2px 4px rgba(0, 255, 255, 0.2);
    height: 72px;
    overflow: hidden;
}

.game-card h5 {
    font-size:15px;
  color: #fff; /* darkcyan for headings to stand out */
  margin: 0 0 10px 0; /* Space below the heading */
}

.game-card p {
  color: #E0FFFF; /* Matching the text color with the overall theme */
  margin: 5px 0; /* Consistent spacing between paragraphs */
}

/* Additional styling for emphasizing certain parts, if needed */
.game-card .highlight {
  color: gold; /* Gold color for important info (optional) */
}

.content{
    display: flex;
    height:unset;
}

.right-content{
    background-image: url("../images/bg2.jpg");
    height: 100vh;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    right: -40px;
}        

